<template>
    <div class="closet-data">
      <div class="container">
  
        <div class="" v-if="outfitClothArray.length > 0">
          <p class="title">關於我的穿搭</p>
  
          <section class="data-section">
            <label class="sub-title">最常穿的衣服</label>
            <p class="mb-2"><span class="text-green">{{outfitMostCategory2Name}}</span>是你的最愛，以下是你最常穿的單品</p>
  
            <div class="row mt-3">
              <div class="col-4" v-for="(item) in outfitClothsSummary" :key="item.clothSerial">
                <ClothBlock :cloth="item.cloth" :categories="clothCategories">
                </ClothBlock>
              </div>
            </div>
          </section>
  
          <section class="data-section">
            <label class="sub-title">最常穿的色系</label>
            <p class="mb-2"><span class="text-green">{{outfitColorRank[0].color.title}}</span>是你的最愛</p>
  
            <div class="bar-grid" v-for="(item, index) in outfitColorRank" :key="index">
              <div class="bar">
                <span :style="{'backgroundColor': item.color.color, 'left': `-${(outfitColorMaxCount - item.count) / outfitColorMaxCount * 100}%` }"></span>
              </div>
              <div class="bar-desc">{{item.count}} 次</div>
            </div>
          </section>
        </div>
  
        <div class="">
          <p class="title">關於我的衣櫥</p>
  
          <section class="data-section">
            <div class="row">
              <div class="col-6">
                總件數：{{clothInTimeRange.length}} 件
              </div>
  
              <div class="col-6">
                總價值：{{clothTotalValueCommaString}} 元
              </div>
            </div>
          </section>
  
          <section class="data-section" v-if="clothColorRank.length > 0">
            <label class="sub-title">色彩統計</label>
            <p class="mb-2"><span class="text-green">{{clothColorRank[0].color.title}}</span>是你衣物中最多的顏色</p>
  
            <div class="bar-grid" v-for="(item, index) in clothColorRank" :key="index">
              <div class="bar">
                <span :style="{'backgroundColor': item.color.color, 'left': `-${(clothColorMaxCount - item.count) / clothColorMaxCount * 100}%` }"></span>
              </div>
              <div class="bar-desc">{{item.count}} 件</div>
            </div>
          </section>
  
          <section class="data-section" v-if="clothBrandRank.length > 0">
            <label class="sub-title">品牌統計</label>
            <p class="mb-2">你的衣物中最多的品牌是<span class="text-green">{{clothBrandRank[0].brand}}</span> ，前五名的數量統計如下</p>
  
            <div class="bar-grid" v-for="(item, index) in clothBrandRank" :key="index">
              <div class="bar">
                <span :style="{'left': `-${(clothBrandMaxCount - item.count) / clothBrandMaxCount * 100}%` }"></span>
              </div>
              <div class="bar-desc">{{item.count}}</div>
              <div class="bar-addition">{{item.brand}}</div>
            </div>
          </section>
  
          <section class="data-section" v-if="clothCategory1Rank.length > 0">
            <label class="sub-title">類別統計</label>
            <p class="mb-2">你的衣物中最多的是<span class="text-green">{{clothCategory1Rank[0].category1Name}}</span> </p>
  
            <div class="section-table">
              <div class="section-table-digital">
                <ul class="digital" v-for="(c1) in clothCategory1Rank" :key="c1.category1">
                  <li class="digital-head">
                    <div class="digital-title">
                      {{c1.category1Name}}
                    </div>
                    <div class="digital-number">
                      {{c1.count}}
                    </div>
                  </li>
                  <li v-for="(c2) in c1.parsed" :key="c2.category2">
                    <div class="digital-title">
                      {{c2.category2Name}}
                    </div>
                    <div class="digital-number">
                      {{c2.count}}
                    </div>
                  </li>
                </ul>
              </div>
  
              <div class="section-table-bar">
                <div class="bar-set" v-for="(c1) in clothCategory1Rank" :key="c1.category1">
                  <div class="bar-grid">
                    <div class="bar">
                      <span :style="{'left': `-${(clothInTimeRangeLength - c1.count) / clothInTimeRangeLength * 100}%` }"></span>
                    </div>
                    <div class="bar-desc">{{(c1.count / clothInTimeRangeLength * 100).toFixed(0)}}%</div>
                  </div>
                  <div class="bar-grid" v-for="(c2) in c1.parsed" :key="c2.category2">
                    <div class="bar">
                      <span :style="{'left': `-${(clothInTimeRangeLength - c2.count) / clothInTimeRangeLength * 100}%` }"></span>
                    </div>
                    <div class="bar-desc">{{(c2.count / clothInTimeRangeLength * 100).toFixed(0)}}%</div>
                  </div>
                </div>
              </div>
            </div>
  
          </section>
  
          <section class="data-section" v-if="mostClothRemainRank.length > 0">
            <label class="sub-title">保有時間統計</label>
            <p class="mb-2">你的衣物中最多<span class="text-green">{{mostClothRemainRank}}</span> </p>
  
            <div class="section-table">
              <div class="section-table-digital">
                <ul class="digital" v-for="(remain, index) in clothRemainRank" :key="index">
                  <li class="digital-head">
                    <div class="digital-title">
                      {{remain.title}}
                    </div>
                    <div class="digital-number">
                      {{remain.count}}
                    </div>
                  </li>
                  <li v-for="(c2) in remain.parsed" :key="c2.category2">
                    <div class="digital-title">
                      {{c2.category2Name}}
                    </div>
                    <div class="digital-number">
                      {{c2.count}}
                    </div>
                  </li>
                </ul>
              </div>
  
              <div class="section-table-bar">
                <div class="bar-set" v-for="(remain, index) in clothRemainRank" :key="index">
                  <div class="bar-grid">
                    <div class="bar">
                      <span :style="{'left': `-${(clothInTimeRangeLength - remain.count) / clothInTimeRangeLength * 100}%` }"></span>
                    </div>
                    <div class="bar-desc">{{(remain.count / clothInTimeRangeLength * 100).toFixed(0)}}%</div>
                  </div>
                  <div class="bar-grid" v-for="(c2) in remain.parsed" :key="c2.category2">
                    <div class="bar">
                      <span :style="{'left': `-${(clothInTimeRangeLength - c2.count) / clothInTimeRangeLength * 100}%` }"></span>
                    </div>
                    <div class="bar-desc">{{(c2.count / clothInTimeRangeLength * 100).toFixed(0)}}%</div>
                  </div>
                </div>
              </div>
            </div>
  
          </section>
  
          <section class="data-section" v-if="mostClothGetStatusRank.length > 0">
            <label class="sub-title">入手的新衣及二手衣</label>
            <p class="mb-2">你最常入手<span class="text-green">{{mostClothGetStatusRank}}</span> </p>
  
            <div class="section-table">
              <div class="section-table-digital">
                <ul class="digital" v-for="(status, index) in clothGetStatusRank" :key="index">
                  <li class="digital-head">
                    <div class="digital-title">
                      {{status.title}}
                    </div>
                    <div class="digital-number">
                      {{status.count}}
                    </div>
                  </li>
                  <li v-for="(c2) in status.parsed" :key="c2.category2">
                    <div class="digital-title">
                      {{c2.category2Name}}
                    </div>
                    <div class="digital-number">
                      {{c2.count}}
                    </div>
                  </li>
                </ul>
              </div>
  
              <div class="section-table-bar">
                <div class="bar-set" v-for="(status, index) in clothGetStatusRank" :key="index">
                  <div class="bar-grid">
                    <div class="bar">
                      <span :style="{'left': `-${(clothInTimeRangeLength - status.count) / clothInTimeRangeLength * 100}%` }"></span>
                    </div>
                    <div class="bar-desc">{{(status.count / clothInTimeRangeLength * 100).toFixed(0)}}%</div>
                  </div>
                  <div class="bar-grid" v-for="(c2) in status.parsed" :key="c2.category2">
                    <div class="bar">
                      <span :style="{'left': `-${(clothInTimeRangeLength - c2.count) / clothInTimeRangeLength * 100}%` }"></span>
                    </div>
                    <div class="bar-desc">{{(c2.count / clothInTimeRangeLength * 100).toFixed(0)}}%</div>
                  </div>
                </div>
              </div>
            </div>
  
          </section>
  
          <section class="data-section" v-if="clothTotalValue > 0">
            <label class="sub-title">衣物價格統計</label>
            <p class="mb-2">你的衣物平均價格為 <span class="text-green">{{clothAvgValueCommaString}}</span> 元</p>
  
            <div class="section-table">
              <div class="section-table-digital">
                <ul class="digital" v-for="(c1) in clothCategory1Rank" :key="c1.category1">
                  <li class="digital-head">
                    <div class="digital-title">
                      {{c1.category1Name}} ({{c1.count}})
                    </div>
                    <div class="digital-number">
                      {{c1.priceSum}} 元 ({{(c1.priceSum / c1.count).toFixed(0)}} / 件)
                    </div>
                  </li>
                  <li v-for="(c2) in c1.parsed" :key="c2.category2">
                    <div class="digital-title">
                      {{c2.category2Name}} ({{c2.count}})
                    </div>
                    <div class="digital-number">
                      {{c2.priceSum}} 元 ({{(c2.priceSum / c2.count).toFixed(0)}} / 件)
                    </div>
                  </li>
                </ul>
              </div>
  
            </div>
  
          </section>
  
        </div>
  
      </div>
  
    </div>
  </template>
  
  
  <script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import ClothBlock from '@/components/ClothBlock.vue';
  import moment from 'moment';
  
  export default {
    name: 'AnalyzeCloset',
    data() {
      return {
        readingBusyName: 'READINGANALYZEDATA',
        outfits: [],
      };
    },
    components: {
      ClothBlock,
    },
    props: {
    },
    beforeDestroy() {
      this.clearComponentBusy(this.readingBusyName);
    },
    async mounted() {
      await this.__refresh();
    },
    computed: {
      ...mapGetters(['isBodyConfigShow']),
      ...mapState(['cloths', 'clothColors', 'clothCategories']),
      outfitClothArray() {
        const outfitCloths = this.outfits.reduce((ary, item) => {
          const {outfitPhotos} = item;
          for (const photo of outfitPhotos) {
            if (photo.type !== 'cloth') {
              continue;
            }
            const {clothSerial} = photo;
            const cloth = this.cloths.filter((c) => {
              return c.serial === clothSerial;
            })[0];
            if (!cloth.isDeleted && cloth.recycleType === -1) {
              ary.push(cloth);
            }
          }
          return ary;
        }, []);
        return outfitCloths;
      },
      outfitCategory2Summary() {
        const category2Ranking = this.outfitClothArray.reduce((ary, item) => {
          const {category2} = item;
          for (const r of ary) {
            if (r.category2 === category2) {
              r.count += 1;
              return ary;
            }
          }
          ary.push({
            category2,
            count: 1,
          });
          return ary;
        }, []);
        const sorted = category2Ranking.sort((a, b) => {
          return b.count - a.count;
        });
        return sorted;
      },
      outfitMostCategory2Name() {
        if (this.outfitCategory2Summary.length === 0) {
          return '';
        }
        const s = this.outfitCategory2Summary[0].category2;
        for (const c of this.clothCategories) {
          for (const c2 of c.sub) {
            if (c2.serial === s) {
              return c2.name;
            }
          }
        }
        return '';
      },
      outfitClothsSummary() {
        const clothRanking = this.outfitClothArray.reduce((ary, item) => {
          for (const r of ary) {
            if (r.clothSerial === item.serial) {
              r.count += 1;
              return ary;
            }
          }
          ary.push({
            clothSerial: item.serial,
            count: 1,
          });
          return ary;
        }, []);
        let sorted = clothRanking.sort((a, b) => {
          return b.count - a.count;
        });
        if (sorted.length > 3) {
          sorted = sorted.splice(0, 3);
        }
        for (const item of sorted) {
          item.cloth = this.cloths.filter((c) => {
            return c.serial === item.clothSerial;
          })[0];
        }
        return sorted;
      },
      outfitColorRank() {
        const colorRank = this.outfitClothArray.reduce((ary, cloth) => {
          for (let i=0;i<ary.length;i++) {
            if (ary[i].colorId === cloth.color) {
              ary[i].count += 1;
              return ary;
            }
          }
          const color = this.clothColors.filter((c) => {
            return c.id === cloth.color;
          });
          const colorCandidate = {
            colorId: cloth.color,
            count: 1,
            color: color[0],
          };
          ary.push(colorCandidate);
          return ary;
        }, []);
        const sortColorRank = colorRank.sort((a, b) => {
          return b.count - a.count;
        });
        return sortColorRank;
      },
      outfitColorMaxCount() {
        if (this.outfitColorRank.length > 0) {
          return this.outfitColorRank[0].count;
        }
        return 1;
      },
      clothInTimeRange() {
        const filterdCloths = this.cloths.filter((cloth) => {
          return cloth.recycleType === -1 && !cloth.isDeleted;
        });
        return filterdCloths;
      },
      clothInTimeRangeLength() {
        return this.clothInTimeRange.length;
      },
      clothTotalValue() {
        const value = this.clothInTimeRange.reduce((sum, cloth) => {
          return cloth.price > 0? sum + cloth.price: sum;
        }, 0);
        return value;
      },
      clothTotalValueCommaString() {
        return this.clothTotalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      clothAvgValueCommaString() {
        return (this.clothTotalValue / this.clothInTimeRange.length).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      clothColorRank() {
        const colorRank = this.clothInTimeRange.reduce((ary, cloth) => {
          for (let i=0;i<ary.length;i++) {
            if (ary[i].colorId === cloth.color) {
              ary[i].count += 1;
              return ary;
            }
          }
          const color = this.clothColors.filter((c) => {
            return c.id === cloth.color;
          });
          const colorCandidate = {
            colorId: cloth.color,
            count: 1,
            color: color[0],
          };
          ary.push(colorCandidate);
          return ary;
        }, []);
        const sortColorRank = colorRank.sort((a, b) => {
          return b.count - a.count;
        });
        return sortColorRank;
      },
      clothColorMaxCount() {
        if (this.clothColorRank.length > 0) {
          return this.clothColorRank[0].count;
        }
        return 1;
      },
      clothBrandRank() {
        const brandRank = this.clothInTimeRange.reduce((ary, cloth) => {
          for (const brand of cloth.brands) {
            let found = false;
            for (let i=0;i<ary.length;i++) {
              if (ary[i].brand === brand) {
                found = true;
                ary[i].count += 1;
                break;
              }
            }
            if (!found) {
              ary.push({
                brand,
                count: 1,
              });
            }
          }
          return ary;
        }, []);
        const sortBrandRank = brandRank.sort((a, b) => {
          return b.count - a.count;
        });
        return sortBrandRank.length > 5 ? sortBrandRank.splice(0, 5): sortBrandRank;
      },
      clothBrandMaxCount() {
        if (this.clothBrandRank.length > 0) {
          return this.clothBrandRank[0].count;
        }
        return 1;
      },
      clothCategory1Rank() {
        const clothCategory1Rank = this.clothInTimeRange.reduce((ary, cloth) => {
          const {category1} = cloth;
          for (const a of ary) {
            if (a.category1 === category1) {
              a.count += 1;
              a.priceSum += cloth.price > 0 ? cloth.price : 0;
              a.sub.push(cloth);
              return ary;
            }
          }
          const category1Name = this.__findCategory1Name(category1);
          ary.push({
            category1,
            category1Name,
            sub: [cloth],
            count: 1,
            priceSum: cloth.price > 0 ? cloth.price : 0,
          });
          return ary;
        }, []);
  
        for (const c of clothCategory1Rank) {
          c.parsed = this.__subParse(c.sub);
        }
        return clothCategory1Rank.sort((a, b) => { return b.count - a.count });
      },
      clothRemainRank() {
        const now = moment();
        const clothRemainRank = this.clothInTimeRange.reduce((ary, cloth) => {
          let type = 4;
          if (cloth.getYear !== -1 && cloth.getMonth !== -1) {
            const clothGetTime = moment().year(cloth.getYear).month(cloth.getMonth - 1);
            const diffYear = moment.duration(now.diff(clothGetTime)).asYears();
            if (diffYear < 1) {
              type = 0;
            } else if (diffYear < 2) {
              type = 1;
            } else if (diffYear < 3) {
              type = 2;
            } else {
              type = 3;
            }
          }
          ary[type].count += 1;
          ary[type].sub.push(cloth);
          return ary;
        }, [{
          title: '1年內',
          count: 0,
          sub: [],
        }, {
          title: '1-2年內',
          count: 0,
          sub: [],
        }, {
          title: '2-3年內',
          count: 0,
          sub: [],
        }, {
          title: '超過3年',
          count: 0,
          sub: [],
        }, {
          title: '無數據',
          count: 0,
          sub: [],
        }]);
  
        for (const c of clothRemainRank) {
          c.parsed = this.__subParse(c.sub);
        }
        return clothRemainRank;
      },
      mostClothRemainRank() {
        let maxName = '';
        let maxCount = 0;
        for (let i=0;i<this.clothRemainRank.length;i++) {
          if (this.clothRemainRank[i].count > maxCount && this.clothRemainRank[i].title !== '無數據') {
            maxCount = this.clothRemainRank[i].count;
            maxName = this.clothRemainRank[i].title;
          }
        }
        return maxName;
      },
      clothGetStatusRank() {
        const clothGetStatusRank = this.clothInTimeRange.reduce((ary, cloth) => {
          const {getStatus} = cloth;
          for (const a of ary) {
            if (a.getStatus === getStatus) {
              a.count += 1;
              a.sub.push(cloth);
              return ary;
            }
          }
          return ary;
        }, [{
          title: '新衣',
          count: 0,
          getStatus: 0,
          sub: [],
        }, {
          title: '二手衣',
          count: 0,
          getStatus: 1,
          sub: [],
        }, {
          title: '無分類',
          count: 0,
          getStatus: -1,
          sub: [],
        }]);
  
        for (const c of clothGetStatusRank) {
          c.parsed = this.__subParse(c.sub);
        }
        return clothGetStatusRank;
      },
      mostClothGetStatusRank() {
        let maxName = '';
        let maxCount = 0;
        for (let i=0;i<this.clothGetStatusRank.length;i++) {
          if (this.clothGetStatusRank[i].count > maxCount && this.clothGetStatusRank[i].title !== '無分類') {
            maxCount = this.clothGetStatusRank[i].count;
            maxName = this.clothGetStatusRank[i].title;
          }
        }
        return maxName;
      },
    },
    watch: {
      isBodyConfigShow(val) {
        if (!val) {
          this.__refresh();
        }
      },
    },
    methods: {
      ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
      async __refresh() {
        this.appendComponentBusy(this.readingBusyName);
        try {
          const list = await this.$store.dispatch('api/readOutfitListPromise');
          this.outfits.splice(0, this.outfits.length);
          for (const outfit of list) {
            this.outfits.push(outfit);
          }
        } catch(err) {
          this.appendErrorMsg(err.toString());
        } finally {
          this.clearComponentBusy(this.readingBusyName);
        }
      },
      __subParse(cloths) {
        const res = [];
        for (const c of cloths) {
          const {category2} = c;
          let found = false;
          for (let i=0;i<res.length;i++) {
            if (res[i].category2 === category2) {
              found = true;
              res[i].count += 1;
              res[i].priceSum += c.price > 0 ? c.price : 0;
              break;
            }
          }
          if (!found) {
            const category2Name = this.__findCategory2Name(category2);
            res.push({
              category2,
              category2Name,
              count: 1,
              priceSum: c.price > 0 ? c.price : 0,
            });
          }
        }
        return res.sort((a, b) => { return b.count - a.count });
      },
      __findCategory1Name(category1) {
        for (const c of this.clothCategories) {
          if (c.serial === category1) {
            return c.name;
          }
        }
        return '';
      },
      __findCategory2Name(category2) {
        for (const c of this.clothCategories) {
          for (const c2 of c.sub) {
            if (c2.serial === category2) {
              return c2.name;
            }
          }
        }
        return '';
      },
    }
  }
  </script>
  
  <style scoped>
    .closet-data {
      padding: .5rem 0;
      color: var(--text-dark);
    }
  
    .title {
      position: relative;
      font-weight: 800;
      font-size: 1.5em;
      width: fit-content;
      letter-spacing: 0.13em;
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
  
    .sub-title {
      font-weight: 1000;
    }
  
    .text-green {
      color: var(--main-green);
    }
  
    .data-section {
      background-color: #f8f8f8;
      border-radius: .5rem;
      padding: .5rem 1rem;
      margin-bottom: .5rem;
    }
  
    .bar-set {
      margin-bottom: .5rem;
    }
  
    .bar-grid {
      /* display: grid; */
      /* grid-auto-columns: max-content; */
      display: flex;
      align-items: center;
    }
  
    .bar {
      position: relative;
      flex: 1 1;
      /* width: 100%; */
      height: 6px;
      border-radius: 999px;
      overflow: hidden;
    }
  
    .bar>span {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 999px;
      border: solid 1px #eee;
      background-color: #444;
    }
  
    .bar-desc {
      flex: 0 0 auto;
      padding: 4px .5em 4px 1em;
    }
  
    .bar-addition {
      flex: 0 0 15%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  
    .section-table {
      display: flex;
      flex-wrap: nowrap;
      font-size: 12px;
    }
  
    .section-table-digital {
      flex: 1 1;
      padding-right: .5rem;
    }
  
    .section-table-bar {
      flex: 0 0 50%;
    }
  
    .digital, .digital>li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    .digital {
      margin-bottom: .5rem;
    }
  
    .digital>li {
      display: flex;
      justify-content: space-between;
      /* padding-left: 12px; */
      /* line-height: 1.8em; */
      padding: 4px 0 4px 12px;
    }
  
    .digital>li.digital-head {
      padding-left: 0;
      font-weight: 600;
      border-bottom: solid 1px rgba(196, 196, 196, 1);
      padding-bottom: 3px;
    }
  </style>
  