<template>
  <div class="cloth-block">
    <div class="cloth-img">
      <div class="cloth-img-inner">
        <img :src="cloth.imgs[0]">

        <div class="lock-mask" v-if="!cloth.isPublic">
          <img :src="require('@/assets/icons/lock.png')" />
        </div>

        <div class="check-icon" v-show="showCheck">
          <img :src="require('@/assets/icons/cloth-check.png')" v-show="!checked">
          <img :src="require('@/assets/icons/cloth-check-active.png')" v-show="checked">
        </div>
      </div>
    </div>
    <p class="category-text" v-if="category2 !== null">{{category2.name}}</p>
    <p class="sub-text" v-if="subText.length > 0">{{subText}}</p>
  </div>
</template>


<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'ClothBlock',
  data() {
    return {
    };
  },
  props: {
    cloth: {
      type: Object,
      required: true,
    },
    dataType: {
      type: Number,
      required: false,
      default: 0,
    },
    showCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
  },
  computed: {
    ...mapState(['clothCategories']),
    category1() {
      const res = this.clothCategories.filter((item) => {
        return item.serial === this.cloth.category1;
      });
      if (res.length > 0) {
        return res[0];
      }
      return null;
    },
    category2() {
      for (const c1 of this.clothCategories) {
        for (const c2 of c1.sub) {
          if (c2.serial === this.cloth.category2) {
            return c2;
          }
        }
      }
      return null;
    },
    showData() {
      switch(this.dataType) {
        case 0:
        return this.category2.name;

        case 1:
        return this.category1.name;

        case 2:
        if (this.cloth.price === -1) {
          return '未設定';
        }
        return `$ ${this.cloth.price}`;
      }
      return '';
    },
    subText() {
      let text = '';
      if (this.cloth.brands.length > 0) {
        text += this.cloth.brands[0];
      }
      return text;
    }
	},
  watch: {
  },
  mounted() {
	},
  methods: {
  }
}
</script>

<style scoped>
  .cloth-block {
    position: relative;
  }

  .cloth-img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    margin-bottom: .3rem;
  }

  .cloth-img-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: .5rem;
    overflow: hidden;
    background-color: #eeeeee;
  }

  .cloth-img-inner>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .category-text {
    color: var(--text-dark);
    /* font-size: 12px; */
    margin-bottom: 0rem;
  }

  .check-icon {
    position: absolute;
    right: .5rem;
    bottom: .5rem;
    width: 24px;
    height: 24px;
  }

  .check-icon>img {
    width: 100%;
    height: 100%;
  }

  .sub-text {
    font-size: 12px;
    color: #C4C4C4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .lock-mask {
    position: absolute;
    /* width: 30%;
    height: 30%;
    top: -15%;
    left: -15%;
    transform: rotateZ(45deg);
    background-color: var(--main-yellow); */
    top: 5px;
    right: 5px;
  }

  .lock-mask>img {
    width: 16px;
    display: block;
  }
</style>
